@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";

.c-two_columns__item {

	p {
		max-width: 63rem;
	}

	&.row {
		  align-items: center;
	}


	&:not(:first-child) {
		margin-top: var(--spacing-60);

		@include mq.mq($from: large) {
			margin-top: var(--spacing-100);
		}
	}
	&-image {
		position: relative;
		transform: translateX(-2rem);
		margin-bottom: 6rem;
		@include mq.mq($from: medium) {
			margin-bottom: 7.2rem;
		}
		&:before {
			content: "";
			z-index: 1;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			transform: translate(6rem,3rem) scale(-1, 1);

			@include mq.mq($from: medium) {
				transform: translate(9rem,3rem) scale(-1, 1);
			}

			@include background-svg.params(svg.$svg-overlay, transparent, left, bottom, no-repeat, 50%, auto, colors.$color-secondary-2);
		}
		&.grey-overlay {
			&:before {
				@include background-svg.params(svg.$svg-overlay-grey, transparent, left, bottom, no-repeat, 50%, auto, colors.$color-secondary-2);
			}
		}
		&.dark-overlay {
			&:before {
				@include background-svg.params(svg.$svg-overlay-dark, transparent, left, bottom, no-repeat, 50%, auto, colors.$color-secondary-2);
			}
		}
		.row--reverse & {
			transform: translateX(2rem);
			&:before {
				transform: translate(-9rem,3rem);
			}
		}
		@include mq.mq($from: medium) {

			&,
			.row--reverse & {
				transform: translateX(0);
			}

		}

		@include mq.mq($from: large) {
				margin: 0 0 12rem -4rem;
				margin-left: -7rem;
				max-width: 792px;

				&:before {
						transform: translate(12rem, 12rem) scale(-1, 1);
				}

				.row--reverse & {
					&:before {
						transform: translate(-12rem, 12rem);
					}
					margin:  0 -4rem 12rem 0;
					margin-right: calc(-7rem - 6rem - (100vw - 740px)/12);
					max-width: 792px;
				}
		}

		@include mq.mq($from: clarge) {
			margin-left: calc(var(--container-width)/2 - 50vw);

			.row--reverse & {
				margin-right: calc(var(--container-width)/2 - 50vw - 13.8rem);
			}
		}

		@include mq.mq($from: large, $until: cwide) {
			.row--reverse & {
				margin-left: calc((100vw - 740px)/12 + 6rem);
			}
		}
		@include mq.mq($from: cwide) {
			margin-left: -16rem;
			.row--reverse & {
				margin-right: -29.8rem;
			}
		}

	}

	.subheader {
		margin-bottom: 3rem;
	}

}

.c-two_columns__item-text {


	@include mq.mq($from: large) {

			margin-bottom: 12rem;


		.row--reverse & {
			padding-left: 0;
		}
	}
}
