@use "../_base/typography";
@use "../mixins/mq";



.c-cta {
	&__wrapper {
		padding: 3rem;
		position: relative;
		margin-left: auto;
		margin-right: -15px;
		width: 100%;

		@include mq.mq($from: medium) {
			margin-right: 0;
			width: 83.33333333%;
			padding: 4rem 4.4rem 4rem 5.9rem ;
		}

		@include mq.mq($from: large) {
			width: 58.333333331%;
			padding-left: 14rem;
			padding-top: 8.3rem;
			padding-bottom: 8rem;
		}

		&--has-contact {
			padding-top: 5.5rem;
			margin-top: 5.5rem;
			@include mq.mq($from: large) {
				padding-top: 8rem;
				margin-top: 8rem;
			}
		}



		&:before {
			content: "";
			pointer-events: none;
			z-index: -1;
	    display: block;
	    box-shadow: -2px 2px 20px rgba(0,0,0,.2);
	    width: 200%;
	    height: 100%;
	    top: 0;
	    position: absolute;
	    left: 0;
		}

	}


	&__cta-contact {
		display: block;
		width: 9rem;

		border-radius: 50%;
		overflow: hidden;
		position: absolute;
		top: 0;
    left: 0;
		transform: translate(-2rem,-5.5rem);


		@include mq.mq($from: medium) {
			width: 10rem;
		}
		@include mq.mq($from: large) {
			width: 16rem;
			transform: translate(-6rem, -4rem);
		}

	}

	&__phone {

		@include mq.mq($from: large) {
			margin-left: -8px;
		}
	}

	&__link {
		&--mt {
			margin-top: 6rem;
		}
	}

}

.contact--wrapper {
	display: flex;
	flex-direction: column;

	@include mq.mq($from: xlarge) {
		flex-direction: row;
	}

	div {
		width: 100%;

		@include mq.mq($from: xlarge) {
			width: 50%;
		}
	}

	a.action {
		font-size: 2.6rem;
		line-height: 4.2rem;
	}
}

.c-cta__page-number {
	@extend .font-weight-600;
	color: var(--color-primary-1);
}
