@use "../mixins/mq";

.component {

}


.c-edge + .c-edge,
.c-spaced + .c-edge {
	margin-top: var(--spacing-60);

	@include mq.mq($from: large) {
		margin-top: var(--spacing-120);
	}
}



.c-last {
	margin-bottom: var(--spacing-60);

	@include mq.mq($from: large) {
		margin-bottom: var(--spacing-120);
	}
}
