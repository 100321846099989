@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";

.footer {
  color: var(--color-white);

  .action-secondary {
    @extend .action-secondary--inverted;
  }
}

.footer__top {
  padding-top: var(--spacing-40);
  padding-bottom: var(--spacing-40);
  background-color: #2A3336;

  @include mq.mq($from: large) {
    padding-top: var(--spacing-60);
    padding-bottom: 85px;
  }
}

.footer__top-left {
  padding-bottom: 7rem;

  @include mq.mq($from: large) {
    padding-bottom: 0;

    p:first-child {
      margin-bottom: 4.8rem!important;
    }
  }
}

.footer__top-right {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  
  @include mq.mq($from: large) {
    flex-direction: row;
  }

  @include mq.mq($from: large) {
    margin-top: 25px;
    justify-content: space-between;
  }
}

.footer__address {
  margin-bottom: var(--spacing-30);
}

.footer__address,
.footer__contact {
  @include mq.mq($from: large) {
    //width: calc(100% * 3 / 6 - #{var(--spacing-60)});
    //@include col.params(6, 12, var(--spacing-60));
    width: auto;
  }
}

.footer__contact {
  
}

.footer__bottom {
  padding-top: var(--spacing-20);
  padding-bottom: var(--spacing-20);
  @extend .font-size-300;
  background-color: var(--color-black);

  .row {
    @include mq.mq($from: large) {
      align-items: center;
      justify-content: space-between;

      .col{
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.footer__bottom-left {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--spacing-60);
  letter-spacing: 0.85px;

  @include mq.mq($from: large) {
    width: auto;
    flex-direction: row;
    margin-bottom: 0;
  }
}
