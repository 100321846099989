@use "../mixins/mq";

.c-three_columns__item-link {
	text-decoration: none;
	color: var(--color-black);
	display: block;
	height: 100%;
}



	.c-three_columns__item {
		position: relative;
		margin-bottom: var(--spacing-60);
    align-self: stretch;

	}

	.c-three_columns__item-line {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-bottom: .4rem solid var(--color-black);
	}

	.c-three_columns__item--as-link {
		margin-bottom: var(--spacing-120);

	}

	.icon.icon--c-three_columns__item-link-arrow {
		position: absolute;
		top: 100%;
		padding: 3rem 0 0;
		width: 3rem;
		height: 6rem;
		right: 0;
		color: var(--color-primary-1);

		transition: transform var(--animationDuration) var(--animationEase);
		transform: translateX(0);

		a:hover:not(:focus) & {
			transform: translateX(-5rem);
		}

	}


.c-three_columns__item-content {
	padding: 3rem 0 var(--spacing-40);



	@include mq.mq($from: large) {

	}

	* + * {
		margin-top: var(--spacing-20);

		@include mq.mq($from: large) {
			margin-top: 1.2rem;
		}
	}
}
