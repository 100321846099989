@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.nav {
	display: inline-flex;
}

.nav__toggle.action {
	position: absolute;
	top: -0.8rem;
	right: -1.6rem;
	width: 6rem;
	height: 6rem;
	transform-origin: center center;
	transition: transform var(--animationDuration) var(--animationEase);
	color: inherit;

	.icon {
		color: var(--color-primary-1);
	}


	.nav__item--parent-opened & {
		transform: rotate(180deg);
	}

	@include mq.mq($from: large) {
		display: none;
		visibility: hidden;
	}
}

.nav__wrap {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 0;
	align-items: center;

	&--parent {
		.nav--main-nav &,
		.nav--meta-nav & {
			@include mq.mq($from: large) {
				justify-content: flex-end;
			}
		}
	}

	&--child {
		padding-top: var(--spacing-10);
		padding-bottom: var(--spacing-20);
		background-color: var(--color-white);

		@include mq.mq($from: large) {
			position: absolute;
			left: 0;
			top: 100%;
			display: none;
			flex-direction: column;
			padding: 0.7rem 2.6rem 2.6rem 2.8rem;
			opacity: 0;
			transform: translate3d(-2rem, 0.2rem, 1px);
			white-space: nowrap;
			box-shadow: 0 2px 20px 0 #1A191920;
		}
	}

	&.nav__wrap--visible {
		display: flex;
		z-index: 9998;
		visibility: visible;
	}
}

.nav__item {
	position: relative;

	.icon-chevron-down,
	.icon-chevron-down svg {
		@include mq.mq($from: large) {
			width: 20px;
			height: 12px;
		}
	}
}

.nav__item--child {
	width: 100%;
	line-height: 4.6rem;

	@include mq.mq($from: large) {
		border-bottom: 2px solid #D8D8D8;
	}

	&:last-child {
		border-bottom: none;
	}

}

.nav__link {
	outline: none;
	text-decoration: none;
	color: var(--color-black);

	&:focus {
		outline: none;
	}

	&.is-active {
		color: var(--color-primary-1);
	}
}

.nav__link--child {
	font-size: 2.1rem;
	line-height: inherit;
	font-weight: 500!important;
	display: inline-flex;
	justify-content: space-between;
	width: 100%;

	&::after {
		content: '';
		width: 65px;
	}

	&:hover {
		color: var(--color-primary-1);

		&::after {
			@include background-svg.params(svg.$svg-arrow-right, transparent, right, center, no-repeat, 22px, 22px, colors.$color-primary-1);
		}
	}
}
