@use "common";
@use "atoms";
@use "molecules";
@use "organisms";

.block {
	padding: var(--spacing-80);
}

.block--inverted {
	background-color: var(--color-grey-300);
}

.block--gray {
	background-color: #F2F0EE;
}

.block--secondary-2 {
	background-color: var(--color-secondary-2);
}


/*
#container {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: -1;
}

body {
	overflow: hidden;
}
*/
