@use "../mixins/mq";

.c-fullscreen {

  &__media {
    min-height: 466px;
    position: relative;
    margin-bottom: 40px;
    width: 100%;
  }
  &__text {
    width: 100%;

    @include mq.mq($from: medium, $until: large){

      br {
        display: none;
      }
     }

    @include mq.mq($from: large, $until: xxlarge){
      
      h1, .h1 {
        font-size: calc(40px + (60 - 40) * ((100vw - 1200px) / (1860 - 1200)));
      }  
    }
    
  }

  .figure {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .inner-text {
    margin-bottom: 75px;

    @include mq.mq($from: large) {
      margin-left: 14rem;
      margin-top: 8rem;
      margin-bottom: 0;
    }
  }

    @include mq.mq($from: large) {

    &__media {
      margin: 0;
      position: absolute;
      height: 100%;
      width: 50%;
      width: calc(50% - 3rem);
    }

    &__text {
      margin: auto 0;

      em {
        &:first-child {
          margin-left: 165px;
        }
        &:nth-child(4) {
          margin-left: 30px;
        }
      }

    }

    &__wrapper {
      height: 100vh;
      max-height: 1080px;
      display: flex;
      align-items: center;

      @include mq.mq($from: large) {
        min-height: 90rem;
      }
    }
  }



/*
	.section {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 80vh;
	}


	*/
}
