@use "../mixins/mq";

.c-logos__logo {
	width: 100%;
	display: inline-flex;
	justify-content: center;
	margin-bottom: var(--spacing-40);

	@include mq.mq($from: medium) {
		width: calc(100% / 3 - #{var(--spacing-20)});
		margin-left: var(--spacing-20);

		&:nth-child(3n + 1) {
			margin-left: 0;
		}
	}

	@include mq.mq($from: large) {
		width: calc(100% / 5 - #{var(--spacing-60)});
		margin-left: var(--spacing-60);

		&:nth-child(3n + 1) {
			margin-left: var(--spacing-60);
		}

		&:nth-child(5n + 1) {
			margin-left: 0;
		}
	}
}
