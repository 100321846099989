@use "../mixins/mq";
@use "../_base/typography";
@use "../_base/svg";
@use "../mixins/background-svg";
@use "../_config/colors";

.nav--main-nav {

	.nav__item--parent {
		position: relative;
		max-height: 6rem;
		width: 100%;
		margin: 0 var(--spacing-20);
		transition: max-height 0.5s var(--animationEase);
		overflow: hidden;
		border-bottom: 1px solid var(--color-primary-1-transparent);

		@include mq.mq($from: medium) {
			margin: 0 45px 0 60px;
		}

		@include mq.mq($from: large) {
			width: auto;
			max-height: 8rem;
			margin-left: 60px;
			margin-right: 0;
			overflow: visible;
			border-bottom: 0;
		}
		
		@include mq.mq($from: large, $until: xlarge) {
			margin-left: calc(35px + (60 - 35) * ((100vw - 1200px) / (1390 - 1200)));
		}
	}

	.nav__item--child {
		margin-bottom: 0;
	}

	.nav__item--parent-opened {
		max-height: 800px;

		@include mq.mq($from: large) {
			max-height: unset;
		}
	}

	.nav__link {
		@extend .font-weight-600;
	}

	.nav__link--parent {
		display: inline-flex;
		height: 5.8rem;
		align-items: center;
		font-size: 2.6rem;
		line-height: 4.2rem;
		letter-spacing: 0.65px;
		font-weight: 500;
		transition: .3s ease-in-out;

		.nav__text {
			line-height: 1;
		}

		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}

		&::before {
			content: '';
			display: inline-flex;
			width: 1.6rem;
			height: 1.6rem;
			margin-right: 10px;
			
			@include background-svg.params(svg.$svg-plus, transparent, center, center, no-repeat, 1.6rem, 1.6rem, colors.$color-primary-1);

			@include mq.mq($until: large) {
				content: unset;
			}
		}
	}

	.nav__item--has-children {

		.nav__toggle {
			@include background-svg.params(svg.$svg-plus, transparent, center, center, no-repeat, 3rem, 3rem, colors.$color-primary-1);
		}

		&.nav__item--parent-opened {
			.nav__toggle {
				@include background-svg.params(svg.$svg-minus, transparent, center, center, no-repeat, 3rem, 3rem, colors.$color-primary-1);
			}	
		}

	}
}
