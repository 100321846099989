@use "../mixins/mq";
@use "../_base/typography";
@use "../atoms/action";

.nav--meta-nav {

	@extend .font-size-300;

	@include mq.mq($from: large) {
		margin-top: 0;
		line-height: 2.5rem;
		letter-spacing: 0.85px;
	}

	@include mq.mq($until: large) {
		.nav__wrap--parent {
			margin: 29px 60px 20px;
	
			.nav__item--parent {
				padding: 11px 0;
				letter-spacing: 1.6px;

				&.nav__item--has-children {
					.nav__wrap--child {
						display: none;
					}
				}

				.nav__button {
					padding: 0;
					color: var(--color-grey-300);
					font-weight: 500;
					line-height: 1;
					min-height: auto;
					background: transparent;
					border: none;
				}
			}
		}
	}

	@include mq.mq($until: medium) {
		.nav__wrap--parent {
			margin: 29px 20px 20px;
		}
	}

	.nav__item--parent {
		width: 100%;

		&:last-child{
			margin-right: 0;
			line-height: 1;
		}


		@include mq.mq($from: large) {
			width: auto;
			margin-right: 31px;
			line-height: 3.3;
		}
	}


	a.nav__button {
			@extend .action;
			@extend .action-primary;
	}

	.nav__link {
		color: var(--color-grey-300);
		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}
	}
}
