@use "../mixins/mq";

:root {
	--teaserbox-color: var(--color-white);
	--teaserbox-bg-opacity: 1;
	--teaserbox-spacer-width: .2rem;
	--teaserbox-body-offset: 6rem 1.8rem 3rem;
	--teaserbox-min-height: 34.8rem;
	--teaserbox-link-min-height: 6rem;
	@include mq.mq($from: medium) {
		--teaserbox-body-offset: 4.4rem 4rem 1.2rem;
		--teaserbox-color: var(--color-black);
		--teaserbox-bg-opacity: 0;
	}
	@include mq.mq($from: large) {
		--teaserbox-spacer-width: .4rem;
		--teaserbox-min-height: 46rem;
		--teaserbox-body-offset: 6.4rem 2.7rem;
		--teaserbox-link-min-height: 7.6rem;
	}
}

.teaserbox {
  position: relative;
  display: block;
  text-decoration: none;
  color: var(--teaserbox-color);
	height: 100%;
	transition: color var(--animationDuration) var(--animationEase);
	min-height: var(--teaserbox-min-height);

	&:hover,
	&:focus {
		--teaserbox-color: var(--color-white);
		--teaserbox-bg-opacity: 1;
	}
	@include mq.mq($from: medium,$until: large) {
		.swiper-slide-active & {
			--teaserbox-color: var(--color-white);
			--teaserbox-bg-opacity: 1;
		}
	}
	@include mq.mq($from: large) {
		.swiper-slide-active + .swiper-slide & {
			--teaserbox-color: var(--color-white);
			--teaserbox-bg-opacity: 1;
		}
	}

  &__bg {
	opacity: var(--teaserbox-bg-opacity);
	transition: opacity var(--animationDuration) var(--animationEase);
    background-color: rgba(#000,.1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	background-color: rgba(0,0,0,.5);
	background-repeat: no-repeat;
	background-size: cover;
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: .5;
			background-color: rgba(0,0,0,.5);
		}
  }

	&__subheader {
		text-transform: uppercase;
		font-size: var(--font-size-200);
		min-height: 4.4rem;
		letter-spacing: .5px;
	}

	&__header {
			margin-bottom: 2.4rem;
	}

  &__body {
		padding: var(--teaserbox-body-offset);
    display: flex;
    flex-direction: column;
    height: 100%;
		position: relative;

  }

	&__link {
			min-height: var(--teaserbox-link-min-height);
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 1.8rem;
			line-height: 1.684210526;
			letter-spacing: 0.8px;
			margin-top: 2rem;
	}

  &__spacer {
    background-color: var(--teaserbox-color);
    display: block;
    height: var(--teaserbox-spacer-width);
  }
	&__footer {
			margin-top: auto;
	}

	.icon--teaserbox {
		width: 2.4rem;
		min-width: 2.4rem;
    height: 3.7rem;
		color: var(--color-primary-1);
	}
}
