@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_base/svg";

.cover {
  display: block;
  background-color: var(--color-black);
  position: relative;

  overflow: hidden;

  &--mood {
    height: 80vh;

    .figure {
      height: 100%;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(0,0,0,.4);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &__footer {
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;


  }

  &__text {
      color: var(--color-white);
    	@include mq.mq($from: medium) {
        width: 74.999999997%;
      }
      @include mq.mq($from: large) {
        width: 41.666666665%;

        h2 {
          margin-bottom: 9.6rem;
        }
      }
  }

  &__dot {
      z-index: 3;
      position: absolute;
      left: var(--x,50%);
      top: var(--y,50%);
      display: flex;
      transform: translate(-50%,-50%);

      &--desktop {
        display: none;
        @include mq.mq($from: medium) {
          display: block;
        }
      }
      &--mobile {
        @include mq.mq($from: medium) {
          display: none;
        }
      }

      &-content {
        position: relative;
        max-width: 100%;
        width: 52rem;
      }

      &-close {
        position: absolute;
        right: 0;
        width: 4rem;
        height: 4rem;
        z-index: 1;
        @include background-svg.params(svg.$svg-close, transparent, center, center, no-repeat, 2.4rem, 2.4rem, colors.$color-black);
      }

      &-overlay {
        position: absolute;
        z-index: 3;

        top: 50%;
        right: 50%;
        max-width: 100%;
        max-width: calc(100% - 4rem);

        opacity: 0;
        transform: translate(50%, -40%);
        transition: transform var(--animationDuration) var(--animationEase), opacity var(--animationDuration) var(--animationEase);
        pointer-events: none;

        &.active {
          pointer-events: all;
          opacity: 1;
          transform: translate(50%, -50%);
          z-index: 5;
        }

        @include mq.mq($from: large) {
          max-width: 48.4rem;
          right: 314px;
        }
      }

      &s {
        list-style: none;
        margin: 0;
        padding: 0;
      }
  }

}
