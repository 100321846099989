@use "../mixins/mq";
@use "../utils/visually-hidden";

.header {
  $el: &;
  height: 7rem;
  position: fixed;
  z-index: 10;

  .overlaid-header & {

    position: absolute;
    z-index: 50;
    top: 0;

    #{$el} {
      &__logo {
        fill: var(--color-white);
      }
    }
  }

  @include mq.mq($from: large) {
    height: var(--headerHeight);
  }
}
.header-wrapper {
  left: 0;
  right: 0;
  height: inherit;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  transition: .3s ease-in-out;
  z-index: 100;
}
.header__nav--opened .header {
  height: var(--headerHeightSmall);
}

.icon-logo > svg > g:last-child {
    visibility: visible;
    opacity: 1;
    transition: .3s ease-in-out;
    transform: translateY(-8px);
}

.logo-wrapper {
  display: flex;
  margin-left: 20px;
  width: 190px;

  @include mq.mq($from: medium) { 
    width: 240px;
  }

  @include mq.mq($from: large) {
    margin-left: 50px;
    width: 526px;
  }
}

.header__home-link {
  display: inline-flex;
  text-decoration: none;
  transition: .3s ease-in-out;
  width: 100%;
  
  > * {
    width: 100%;
    transition: .3s ease-in-out;
    
    @include mq.mq($from: large) {
      margin-left: var(--spacing-70);
      margin-top: 0.7rem;
    }
  }
}

.header__home-link-sitename {
  align-self: center;
  margin-left: 20px;
  font-size: 20px;
  color: black;
  width: auto;
}

.header__home-link-text {
  @extend .visually-hidden;
}

.header__nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 12rem;
  padding-bottom: 5rem;
  z-index: 1;
  transform: translate3d(100%, 0, 0);
  background-color: var(--color-white);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: transform var(--animationDuration) var(--animationEase);
  

  @include mq.mq($from: medium, $until: large) {
    width: 54.7rem;
  }

  @include mq.mq($from: large) {
    position: relative;
    position: unset;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 3.3rem 0 0;
    transform: none;
    background: none;
    overflow: visible;
    transition: .3s ease-in-out;
  }

  .header__nav--opened & {
    transform: translate3d(0, 0, 0);

    @include mq.mq($from: medium) {

    }
    @include mq.mq($from: large) {
      transform: none;
    }
  }
}

.header__nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-primary-1-transparent);
  display: none;
  z-index: 0;

  .header__nav--opened & {
    @include mq.mq($from: medium, $until: large) {
      display: block;
    }
  }
}


.header__nav-meta {
  @include mq.mq($from: large) {
    position: absolute;
    right: 3.3rem;
    top: 1.6rem;
    padding-right: var(--spacing-70);
    transition: .3s ease-in-out;
  }
}

.header__hamburger {
  position: relative;
  margin-right: 1.7rem;
  z-index: 2;
  display: flex;
  align-items: center;
  color: var(--color-grey-400);

  .overlaid-header & {
    color: var(--color-white);
  }

  @include mq.mq($from: medium) {
    margin-right: 0.9rem;
  }

  @include mq.mq($from: large) {
    margin-right: 2.5rem;
    display: none;
    visibility: hidden;
  }

  .header__nav--opened & {
    color: var(--color-black-transparent);
  }
}

.header--scroll {
  
  .header-wrapper {
    height: var(--headerHeightSmall);
    box-shadow: 0 2px 20px 0 #1A191920;
    background-color: var(--color-white);
    @include mq.mq($from: large) {
      height: 8rem;
    }
  }

  .header__nav-meta {
    @include mq.mq($from: large) {
      top: -10rem;
    }
  }
  .nav--main-nav .nav__link--parent {
		height: 8rem;
  }

  .nav--meta-nav .nav__item--parent {
    @include mq.mq($from: large) {
      visibility: hidden;
      opacity: 0;
      transition: .3s ease-in-out;
    }
  }

  .header__home-link {

    @include mq.mq($from: large) {
      padding-top: 0;
    }

    > * {
 
      @include mq.mq($from: large) {
        margin-top: 0;
      }
    }
  }

  .logo-wrapper > * {
    @include mq.mq($from: large) {
      width: 320px;
    }
  }

  .icon-logo > svg > .header__logo {
    fill: black;

    &:nth-child(4) {
      fill: #616569;
    }
    &:nth-child(3) {
      fill: var(--color-primary-1);
    }
  }

  .header__hamburger {
    color: var(--color-grey-400);
  }
}